import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";
import "pure-react-carousel/dist/react-carousel.es.css";



  const IndexPage = () => {
  // state
  const [a1, setWeight] = useState(0)
  const [a2, setHeight] = useState(0)
  const [a3, setHeight1] = useState(0)
  const [a4, setHeight2] = useState(0)
  const [bmi, setBmi] = useState('')
  const [bmi1, setBmi1] = useState('')
  const [bmi2, setBmi2] = useState('')
  const [bmi3, setBmi3] = useState('')
  const [bmi4, setBmi4] = useState('')
  const [bmi5, setBmi5] = useState('')
  const [bmi7, setBmi7] = useState('')
  const [bmi6, setBmi6] = useState('')
  const [bmi8, setBmi8] = useState('')
  const [bmi9, setBmi9] = useState('')
  const [message, setMessage] = useState('')
  const [shopStat, setshopChange] = useState(false);
  let calcBmi = (event) => {
    //prevent submitting to the server
    event.preventDefault()
 
    if (a1 === 0 || a2 === 0 || a3 === 0 || a4 === 0) {
      alert('Please enter a valid Data')
    } else {
      let bmi = (( a2*a3*a4/100))
      setBmi(bmi.toFixed(1))
      let bmi1 = (( a3*40/100))
      setBmi1(bmi1.toFixed(1))
      let bmi2 = (( a3*a4/52))
      setBmi2(bmi2.toFixed(1))
      let bmi3 = (( (bmi)-4400))
      setBmi3(bmi3.toFixed(1))
      let bmi4 = (( (bmi)-400))
      setBmi4(bmi4.toFixed(1))
      let bmi5 = (( (bmi3)/4400*100))
      setBmi5(bmi5.toFixed(1))
      let bmi6 = (( (bmi3+bmi4)/4800*100))
      setBmi6(bmi6.toFixed(1))
      let bmi7 = (( (bmi3+bmi4)/5200*100))
      setBmi7(bmi7.toFixed(1))
      let bmi8 = (( (bmi3+bmi4)/5600*100))
      setBmi8(bmi8.toFixed(1))
      let bmi9 = (( (bmi3+bmi4)/6000*100))
      setBmi9(bmi9.toFixed(1))
      // Logic for message
 
      // if (bmi < 25) {
      //   setMessage('You are underweight')
      // } else if (bmi >= 25 && bmi < 30) {
      //   setMessage('You are a healthy weight')
      // } else {
      //   setMessage('You are overweight')
      // }
    }
  }
 

  let reload = () => {
    window.location.reload()
  }
 
    return (
  <Layout>
    <SEO title="Home" />
  
    <div className="bg-[url('/images/bot-bg.png')] bg-cover p-[9rem] lg:mt-[-3rem]">
        
        <h2 className="text-center teamtitle text-white">
        RPA ROI Savings Calculator
          </h2><br/>
          
          
          <p className="text-center teamp text-white">Return on Investment for Robotic Process Automation (ROI RPA).</p>
          
       
                </div>
    
<Container>
<div className="bg-grey-500 text-center mt-[7rem]">
  
  <h2 className="text-center teamtitle">
  HOW LONG WILL IT TAKE TO SHOW RETURN ON INVESTMENT (ROI) FOR ROBOTIC PROCESS AUTOMATION?
          </h2>
          
          
         
          
          
          <p className="text-center botp">Use the ROI calculator below to determine whether Robotic Process Automation (RPA) would be a good financial fit for your organization. The calculator below estimates ROI based on what we refer to as one concurrent bot. One concurrent bot can be programmed to login and work within multiple systems, and complete a wide variety of tasks 24 x 7 x 365. If there is excess capacity, your bot can be used for other tasks. Best of all, they don’t get sick and are not prone to human error! If you need help brainstorming a task to estimate, see our blog that discusses RPA use cases. Complete the fields in the calculator below to find your ROI for RPA..</p>
          
</div>
<div className="flex items-center justify-center">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
        
                </div>
</Container>
<Container>
<div className=" lg:p-[7rem] lg:mt-[5rem] htl">
<h2 className="text-center teamtitle ">
CALCULATE YOUR SAVINGS
          </h2><br/>
          
          
          <form onSubmit={calcBmi}> 
          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 place-items-center ">
         
          <div className="mb-4">
    <label className="lp">Number of business processes The RPA bot will automate?</label>
    <input type="number"  onChange={(event) => setWeight(+event.target.value)} className="hm46 mtl1 bd h-3 shadow-sm bg-gray-50 border rounded-lg  w-full p-5  " placeholder="" />
  </div>
  <div className="mb-4">
    <label className="lp">How many FTEs currently work on the task(s)?</label>
    <input type="number"  onChange={(event) => setHeight(+event.target.value)} className="hm46 mtl1 bd h-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="" />
  </div>
  <div className="mb-4">
    <label className="lp">What percentage of the FTE(s) daily time is spent on this task?</label>
    <input type="number"  onChange={(event) => setHeight1(+event.target.value)} className="hm46 mtl1  bd h-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="" />
  </div>
  <div className="mb-4">
    <label className="lp">What is the average salary of the FTE(s) performing this task?</label>
    <input type="number"  onChange={(event) => setHeight2(+event.target.value)} className="mtl1 bd h-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="" />
  </div>




            </div>
            <div className="flex items-center justify-center">
          <button  onClick={() => setshopChange(!shopStat)} className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded" type='submit'>Calculate ROI</button>
        
                </div>
                </form>
</div>
<table id="customers" className={shopStat===true ? "b3" : "b2"} mt-5>
  <thead>
    <tr>
      <th>Result</th>
      <th>Month 1</th>
      <th>Month 2</th>
      <th>Month 3</th>
      <th>Month 4</th>
      <th>Month 5</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Your Current Cost Per Year to Complete Task(s) w/FTEs</td>
      <td>{bmi}</td>
      <td>{bmi}</td>
      <td>{bmi}</td>
      <td>{bmi}</td>
      <td>{bmi}</td>
      
    </tr>
    <tr>
      <td>Hours Per Week for Your FTE(s) to Complete Task</td>
      <td>{bmi1}</td>
      <td>{bmi1}</td>
      <td>{bmi1}</td>
      <td>{bmi1}</td>
      <td>{bmi1}</td>
      
    </tr>
    <tr>
      <td>Cost Per Week for Your FTE(s) to Complete Task(s)</td>
      <td>{bmi2}</td>
      <td>{bmi2}</td>
      <td>{bmi2}</td>
      <td>{bmi2}</td>
      <td>{bmi2}</td>
      
    </tr>
    <tr>
      <td>Total Annual Cost to Implement Bot and Implement Task(s)</td>
      <td>4400</td>
      <td>400</td>
      <td>400</td>
      <td>400</td>
      <td>400</td>
    </tr>
    <tr>
      <td>Net Return on Investment (ROI)</td>
      <td>{bmi3} 3333</td>
      <td>{bmi4} 444</td>
      <td>{bmi4}</td>
      <td>{bmi4}</td>
      <td>{bmi4}</td>
      
    </tr>
    <tr>
      <td>Annual Accrued ROI</td>
      <td>{bmi5} %</td>
      <td>{bmi6} %</td>
      <td>{bmi7}</td>
      <td>{bmi8}</td>
      <td>{bmi9}</td>
      
    </tr>

  </tbody>
</table>
<div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 place-items-center mt-[7rem]">
  <div className="clht">
  <h1 className="mt-4 bg2">Lorem ipsum dolor
sit amet</h1>
  <p className="mt-4 fs12"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
 </div>
  
  <div className=" ">

  <form >
  <h1 className="mt-4  mt-[7rem] hc">Book A Demo</h1>
  <div className="mb-4  mt-[1rem] ">
    
    <input type="text"    placeholder="Name"  className=" clf shadow-sm text-gray-900 text-sm rounded-lg  block w-full p-5 "  />
  </div>
  <div className="mb-4">
  
    <input type="email"  placeholder="Email"  className="clf shadow-sm  text-sm rounded-lg  block w-full p-5 " />
  </div>
  <div className="mb-6">
  
    <input    placeholder="Number" className="clf shadow-sm  block w-full p-5" />
  </div>
 <p className="fs12">By proceeding, I agree to Rapid Accelaration Partners, Inc’s Terms of Use and acknowledge<br/>that I have read the Privacy Policy.</p>
  <p className="fs12">I also agree that Rapid Acceleration Partners, Inc or its representatives may contact me by <br/>email, phone, at the email address or number I provide for marketing purposes.</p>
  <button type="submit" className="bookbtn booktext text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Book Now</button>
  <div className="message"></div>
</form>

  
  </div>
  
</div>

</Container>

<br/>

  
  </Layout>
 ) }

export default IndexPage
