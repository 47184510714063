import React from "react"

type Props = {
  children: React.ReactNode
  className?: string
}

function Container({ children, className }: Props): JSX.Element {
  return <div className={`mx-auto max-w-7xl ${className}`}>{children}</div>
}

export default Container
